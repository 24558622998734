import './App.css';
import ilyaVideo from "./images/ilyaGame.mp4"
import mmVideo from "./images/mm.mp4"
import egeBot from "./images/egeBot.png"
import jwifcrew from "./images/jwifcrew.png"
import mmweek from "./images/mmweek.jpg"

import './Card'
import Card from "./Card";
import CardBlock from "./CardBlock";

const info = {
    egeCheckBot: {
        title: "Telegram Bot to automatically check the EGE results in Russia",
        description: "Fast and easy-to-use bot with the ability to send notifications about the new result of the United State Exam (EGE).\nHas been used by >161 000 students in 2022.",
        photoUrl: egeBot,
        coverColor: '#5790b9',
        coverAspect: 1440 / 616.0,
        url: "https://github.com/amanokh/EgeCheckBot",
        technologies: ["Python", "PostgreSQL", "Telegram Bot API"],
        linkType: "github",
    }, vkCleaner: {
        title: "Android app for total or selective cleaning of posts in VK",
        description: "Was created in period of mass prosecutions against Russian users of VK social network.",
        url: "https://github.com/amanokh/VK_WallCleaner",
        technologies: ["Java", "VK API"],
        linkType: "github",
    }, ilya18: {
        title: "Online-quest for my best friend on his 18th birthday",
        description: "An interactive web-game, where opening an each next step involved scanning a QR-code, hidden in a specific place on a gift-box.",
        videoUrl: ilyaVideo,
        coverColor: '#ffd47f',
        coverAspect: 720 / 480.0,
        url: "https://ilya18.manokh.com",
        technologies: ["JavaScript", "Bootstrap"],
        linkType: "external",

    }, irkutsk: {
        title: "Online-quiz on Irkutsk’s city history",
        description: "An interactive web-quiz for checking the acquired knowledge, integrated into the school subject study program.",
        url: "https://manokh.com/irkutsk",
        technologies: ["JavaScript", "Bootstrap", "Yandex Maps API"],
        linkType: "external",

    }, mmDesign: {
        title: "Mathematics and Mechanics Faculty of SPBU identity design",
        videoUrl: mmVideo,
        coverColor: '#002770',
        coverAspect: 1440 / 616.0,
        url: "https://www.behance.net/gallery/149835043/Mathematics-Mechanics-Faculty-of-SPBU-identity-design",
        linkType: "behance",

    }, jwifcrew: {
        title: "Identity of JWIF Crew robotics team",
        photoUrl: jwifcrew,
        coverColor: '#1e1e1e',
        coverAspect: 700 / 300.0,
        url: "https://vk.com/jwifcrew",
        linkType: "external",

    }, mmweek: {
        title: "Identity of MM Entertainment Week",
        photoUrl: mmweek,
        coverColor: '#FEA585',
        coverAspect: 700 / 300.0,
        url: "https://vk.com/mmweek",
        linkType: "external",

    },
}
const colorSchemes = {
    deepBlue: {
        accentColor: "bg-deepBlue",
        textColor: "text-white",
        contrastColor: "white",
    },
    freshOrange: {
        accentColor: "bg-freshOrange",
        textColor: "text-white",
        contrastColor: "white",
    },
    notGray: {
        accentColor: "bg-notGray",
        textColor: "text-black",
        contrastColor: "black",
    },
}

function App() {
    return (
        <div className="mx-auto p-8 md:max-w-screen-sm lg:max-w-[870px]">
            <h1 className="text-3xl font-medium my-4">My projects</h1>

            <CardBlock title="Software">
                <Card colorScheme={colorSchemes.deepBlue}
                      info={info.egeCheckBot}/>
                <div className="w-full lg:flex flex-row gap-6">
                    <Card colorScheme={colorSchemes.deepBlue}
                          info={info.vkCleaner}/>
                    <div className="basis-1/2"/>
                </div>
            </CardBlock>

            <CardBlock title="Web">

                <div className="w-full lg:flex flex-row gap-6">
                    <Card colorScheme={colorSchemes.freshOrange} info={info.ilya18}/>
                    <div className="basis-1/2">
                        <Card colorScheme={colorSchemes.freshOrange} info={info.irkutsk}/>
                    </div>

                </div>
            </CardBlock>
            <CardBlock title="Design">

                <Card colorScheme={colorSchemes.notGray} info={info.mmDesign}/>
                <div className="w-full lg:flex flex-row gap-6">
                    <Card colorScheme={colorSchemes.notGray} info={info.mmweek}/>
                    <Card colorScheme={colorSchemes.notGray} info={info.jwifcrew}/>
                </div>
            </CardBlock>

        </div>);
}

export default App;
