import mePhoto from "./images/me.jpg"

function Footer() {
    return (
        <div className="w-full h-max bg-lightGray dark:bg-darkGray">
            <div className="mx-auto p-8 md:max-w-screen-sm lg:max-w-[870px]">
                <h1 className="text-3xl font-medium my-4">Contact me</h1>
                <div className="w-full flex flex-col md:flex-row md:items-center gap-8">
                    <div>
                        <img src={mePhoto} className="w-72 -ml-6 absolute z-0 p-6 -rotate-[5deg] brightness-50" alt="Anton Manoknin"/>
                        <img src={mePhoto} className="w-72 -left-6 relative z-10 p-6" alt="Anton Manoknin"/>
                    </div>
                    <div className="flex flex-col lg:flex-row items-start gap-x-12 gap-y-8">
                    <div className="flex-row">
                        <a href="https://github.com/amanokh" className="text-2xl block w-min font-mono underline after:inline-block after:w-0 after:ml-1 after:-mr-1 after:font-icons after:content-link after:no-underline hover:after:opacity-50 after:opacity-0 after:transition-opacity">github</a>
                        <a href="https://linkedin.com/in/amanokh" className="text-2xl block w-min font-mono underline after:inline-block after:w-0 after:ml-1 after:-mr-1 after:font-icons after:content-link after:no-underline hover:after:opacity-50 after:opacity-0 after:transition-opacity">linkedin</a>
                        <a href="https://instagram.com/amanokh" className="text-2xl block w-min font-mono underline after:inline-block after:w-0 after:ml-1 after:-mr-1 after:font-icons after:content-link after:no-underline hover:after:opacity-50 after:opacity-0 after:transition-opacity">instagram</a>
                    </div>
                    <div className="">
                        <a href="https://t.me/amanokh" className="text-2xl block w-min font-mono underline after:inline-block after:w-0 after:ml-1 after:-mr-1 after:font-icons after:content-link after:no-underline hover:after:opacity-50 after:opacity-0 after:transition-opacity">telegram</a>
                        <p className="text-2xl box-content w-full font-mono">a@manokh.com</p>
                    </div>
                    </div>

                </div>
                <p className="text-gray-500 pt-16 text-sm opacity-50">© Anton Manokhin, 2024. All rights reserved</p>
            </div>
        </div>)
}

export default Footer;
