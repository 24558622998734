import './Banner.css'
import React from "react";

function Banner() {
    return (
        <div className="w-full h-96 bg-notGray dark:bg-darkGray overflow-hidden relative grid-draw banner">
            <Ball addBallClass="ball-1" size={32}/>
            <Ball addBallClass="ball-2" size={24}/>
            <Ball addBallClass="ball-3 dark:hidden" size={16}/>
            <div className="w-full h-full noise absolute"/>
            <div className="mx-auto p-8 md:max-w-screen-sm lg:max-w-[870px] z-50 relative">
                <h1 className="text-5xl md:text-6xl font-title mt-32 mb-4 text-[#141115] dark:text-white">Anton
                    Manokhin</h1>
                <h2 className="text-xl font-light text-[#141115] dark:text-white">Full Stack Engineer with design experience</h2>
            </div>
        </div>)
}

class Ball extends React.Component {
    constructor(props) {
        super(props);
        this.x = Math.random() * document.body.clientWidth;
        this.y = Math.random() * 24 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        this.dirX = Math.random() * 1.5 - 0.75;
        this.dirY = Math.random() * 1.5 - 0.75;
        this.state = {
            top: `${this.y}px`,
            left: `${this.x}px`
        }

        this.ballSize = props.size;
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            20
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        const screenHeight = 24;
        const screenWidth = document.body.clientWidth;
        const speed = 0.8;
        const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

        if (this.y >= screenHeight * rem) {
            let cos = Math.random() * 2 - 1;
            this.dirY = -speed * (1 - cos * cos);
            this.dirX = speed * cos;
        } else if (this.y < 0) {
            let cos = Math.random() * 2 - 1;
            this.dirY = speed * (1 - cos * cos);
            this.dirX = speed * cos;
        } else if (this.x >= screenWidth) {
            let sin = Math.random() * 2 - 1;
            this.dirY = speed * sin;
            this.dirX = -speed * (1 - sin * sin);
        } else if (this.x  < 0) {
            let sin = Math.random() * 2 - 1;
            this.dirY = speed * sin;
            this.dirX = speed * (1 - sin * sin);
        }
        this.x += this.dirX * speed;
        this.y += this.dirY * speed;
        this.setState({
            top: `${this.y}px`,
            left: `${this.x}px`,
        })
    }

    render() {
        return (
            <div style={{
                top: this.state.top,
                left: this.state.left,
                width: `${this.props.size}rem`,
                height: `${this.props.size}rem`,
                marginTop: `${-this.props.size / 2}rem`,
                marginLeft: `${-this.props.size / 2}rem`,
            }}
                className={`absolute rounded-b-full rounded-t-full dark:mix-blend-screen ${this.props.addBallClass}`
            }/>
        );
    }
}


export default Banner;