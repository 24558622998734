import React from 'react';

function CardBlock(props) {
    return (
        <div className="flex flex-col mb-8">
            <h2 className="text-2xl my-4">{props.title}</h2>
            {props.children}
        </div>
    );
}


export default CardBlock;