function Card(props) {
    let photo;
    let title;
    let description;
    let techStack;

    if (props.info.hasOwnProperty("photoUrl")) {
        photo = <img className="object-cover w-full bg-slate-50/25 rounded-t-[.95rem]"
                     style={{aspectRatio: props.info.coverAspect, backgroundColor: props.info.coverColor}}
                     src={props.info.photoUrl} alt={props.info.title}/>;
    }
    if (props.info.hasOwnProperty("videoUrl")) {
        photo = <video className="object-cover w-full bg-slate-50/25 rounded-t-[.95rem]"
                       style={{aspectRatio: props.info.coverAspect, backgroundColor: props.info.coverColor}}
                       src={props.info.videoUrl} autoPlay playsInline loop muted/>;
    }

    let dynamicClassLink = "after:inline-block after:w-0 after:font-normal after:ml-1 after:-mr-1 after:font-icons after:leading-6 group-hover:after:opacity-50 after:opacity-0 after:transition-opacity";
    if (props.info.linkType === "external") {
        dynamicClassLink += " after:content-link"
    } else if (props.info.linkType === "github") {
        dynamicClassLink += " after:content-link-git"
    } else if (props.info.linkType === "youtube") {
        dynamicClassLink += " after:content-link-youtube"
    } else if (props.info.linkType === "behance") {
        dynamicClassLink += " after:content-link-behance"
    }


    title = <h3 className={`text-2xl font-bold pr-6 inline-block w-full ${dynamicClassLink}`}>
        {props.info.title}
    </h3>

    if (props.info.hasOwnProperty("description")) {
        description = <p className="text-sm mt-4">
            {props.info.description}
        </p>
    }
    if (props.info.hasOwnProperty("technologies")) {
        techStack = TechStack(props.info.technologies)
    }
    return (
        <div
            className={`${props.colorScheme.accentColor} ${props.colorScheme.textColor} group basis-1/2 shadow-md flex-row rounded-2xl w-full mb-6 hover:scale-103 transition will-change-transform`}>
            <a href={props.info.url} target="_blank" rel="noreferrer">
                {photo}
                <div className="p-6">
                    {title}
                    {description}
                    {techStack}
                </div>
            </a>
        </div>
    );
}


function TechStack(stackList) {
    return (
        <div className="flex flex-row flex-wrap gap-2 pt-6">
            {stackList.map((technology) => (
                <div key={technology} className="bg-white/75 px-4 rounded-3xl w-min">
                    <p className="text-xs text-black py-1.5 -mx-1.5 whitespace-nowrap">{technology}</p>
                </div>
            ))}
        </div>
    )
}

export default Card;
